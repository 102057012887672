<template>
  <div id="header-ribbon">

    <header>
      <a id="mun-link" v-if="isCipMunksjoUrl()" :href="getBrandUrl('M')" class="navbar-brand">
        <img id="mun-logo" src="@/assets/munksjoe_logo.png" alt="Munksjö">
      </a>

      <a id="ahlstrom-link" v-else-if="isCipAhlstromUrl()" :href="getBrandUrl('A')" class="navbar-brand">
        <img id="ahlstrom-logo" src="@/assets/ahlstrom_logo.png" alt="Ahlstrom">
      </a>

      <nav class="navbar navbar-light bg-light navbar-expand-lg">

        <a v-if="!isCipMunksjoUrl() && !isCipAhlstromUrl()" :href="getBrandUrl('AM')" class="navbar-brand">
          <div class="am-logo-background"></div>
          <img id="am-logo" src="@/assets/ahlstrom_munksjo_header_logo.png" alt="Ahlstrom-Munksjö">
        </a>

        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse flex-column" id="navbarCollapse">

          <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-if="user != null"> <!--  && businessArea == 'S' -->
              <router-link to="/news" class="nav-link" id="news" data-toggle="tooltip" data-placement="left"
                           :title="$t('news.available')" v-if="unreadNewsCount > 0">
                <font-awesome-icon icon="newspaper"/>
                <span class="badge badge-info" v-if="unreadNewsCount > 0">{{ unreadNewsCount }}</span>
              </router-link>
            </li>

            <li class="nav-item float-left" id="bus-area-li" v-if="user != null && visBusinessAreas.length > 1">
              <div class="btn-group">
                <button v-for="visBa in visBusinessAreas" type="button"
                        class="btn btn-bus-area btn-light" :class="{active: businessArea == visBa.code }"
                        @click="businessArea = visBa.code" :key="visBa.code">
                  {{ visBa.name }}
                </button>
              </div>
            </li>

            <li class="nav-item" v-if="user != null">
              <router-link to="/mysettings" class="nav-link" id="my-settings">
                <span>{{ user.firstName }} {{ user.lastName }}</span>
              </router-link>
            </li>
          </ul>

          <cip-menu/>

        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import CipMenu from './CipMenu'
import * as cipDateLib from '../cipDateLib'
import * as cipLib from '../cipLib'
import auth from '../auth'
import cipGraphQLClient from '@/cipGraphQLClient'
import $ from 'jquery'

export default {
  name: 'cip-header',
  inject: ['eventBus'],
  components: {
    CipMenu,
  },
  data() {
    return {
      unreadNewsCount: 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    visBusinessAreas() {
      return auth.buildVisBusinessAreas(this.user, this)
    },
    businessArea: {
      get() {
        return this.$store.state.businessArea
      },
      set(businessArea) {
        this.$store.commit('setBusinessArea', businessArea)
      },
    },
  },
  watch: {
    user(user) {
      if (user != null) {
        this.setUserBusinessArea()
        this.setUnreadNewsCount()
      }
    }
  },
  created: function () {
    /* why is this not necessary?
    if (this.user != null) {
        this.setUserBusinessArea()
        this.setUnreadNewsCount()
    }
    */

    this.eventBus.$on('news-read', () => {
      this.setUnreadNewsCount()
    })
  },
  updated: function () {
    // TODO ... not working in mounted ...
    //console.log('Cip Header: in updated')
    //console.log($('[data-toggle="tooltip"]').length)
    //console.log($('#newsLink').length)
    $('[data-toggle="tooltip"]').tooltip()
    /*Vue.nextTick()
    .then(() => {
        $('[data-toggle="tooltip"]').tooltip()
    })*/
  },
  methods: {
    setUnreadNewsCount() {
      const query =
        `query newsUnreadCount($newsType: String, $atDateTime: LocalDateTime!) {
                    newsUnreadCount(newsType: $newsType, atDateTime: $atDateTime)
                }`
      cipGraphQLClient.request(query, {
        newsType: 'NEWS',
        atDateTime: cipDateLib.createLocalDateTime(new Date()),
      })
        .then(data => {
          this.unreadNewsCount = data.newsUnreadCount
        })
    },

    setUserBusinessArea() {
      //console.log('setting businessArea. businessAreas = ', this.visBusinessAreas)
      if (this.visBusinessAreas.length > 0) {
        // try to keep the last selection
        if (this.visBusinessAreas.find(ba => ba.code === this.businessArea) == undefined) {
          //console.log("initialising businessArea to " + bas[0].code + " - previous businessArea " + this.businessArea)
          this.businessArea = this.visBusinessAreas[0].code
        }
      } else {
        this.businessArea = null
      }
    },

    getBrandUrl(forCmpy) {
      if (this.user != null)
        return '#/dashboard'
      else {
        if (forCmpy == "M")
          return 'https://www.munksjo.com';
        else if (forCmpy == "A")
          return 'https://www.ahlstrom.com';
        else
          return 'https://www.ahlstrom-munksjo.com';
      }
    },

    isCipMunksjoUrl() {
      return cipLib.doCheckAmSeparation() && cipLib.isCipMunksjoUrl(location.href)
    },

    isCipAhlstromUrl() {
      return cipLib.doCheckAmSeparation() && cipLib.isCipAhlstromUrl(location.href)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../node_modules/bootstrap/scss/_functions';
@import '../../node_modules/bootstrap/scss/_variables';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints';
@import '../variables';

#header-ribbon {
  background-color: white;
}

header {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.navbar {
  padding: 0px;
  background-color: #fff !important; // override bg-light
}

.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
  //padding-left: 8px;
}

#mun-link {
  position: absolute;
  left: 0px;
  z-index: 1;
}

#ahlstrom-link {
  position: absolute;
  left: 0px;
  z-index: 1;
}

.am-logo-background {
  //z-index: -100;
  background-color: $color-violet;
  position: absolute;
  top: 0px;
  right: 100%;
  width: 5000px;
  bottom: 0px;
}

#news {
  color: $color-dark-grey;
}

#news:hover {
  color: $color-orange;
}

#my-settings {
  padding-right: 0px;
  color: $color-dark-grey;
}

#my-settings:hover {
  color: $color-orange;
}

/*
.sel-bus-area {
    border-width: 0px;
}
*/

.btn-bus-area {
  font-size: 15px;
  color: $color-dark-grey;
  text-transform: uppercase;
  border: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 8px;
}

.btn-bus-area:hover {
  color: $color-orange;
}

.btn-bus-area.active {
  font-size: 15px;
  font-weight: 400;
}

@include media-breakpoint-up(xs) {
  #mun-logo {
    height: 27px;
    margin-top: 14px;
    margin-bottom: 13px;
    margin-left: 27px;
    margin-right: 27px;
  }
  #ahlstrom-logo {
    height: 54px;
    margin-left: 27px;
    margin-right: 27px;
  }
  #am-logo {
    height: 54px;
  }
  header {
    width: 100%;
  }
  .navbar {
    height: 54px;
  }
}

@include media-breakpoint-up(md) {
  #mun-logo {
    height: 38px;
    margin-top: 19px;
    margin-bottom: 18px;
    margin-left: 38px;
    margin-right: 38px;
  }
  #ahlstrom-logo {
    height: 75px;
    margin-left: 38px;
    margin-right: 38px;
  }
  #am-logo {
    height: 75px;
  }
  header {
    width: 750px;
  }
  .navbar {
    height: 75px;
  }
}

@include media-breakpoint-up(lg) {
  header {
    width: 970px;
  }
}

@include media-breakpoint-up(xl) {
  #mun-logo {
    height: 48px;
    margin-top: 25px;
    margin-bottom: 24px;
    margin-left: 48px;
    margin-right: 48px;
  }
  #ahlstrom-logo {
    height: 97px;
    margin-left: 48px;
    margin-right: 48px;
  }
  #am-logo {
    height: 97px;
  }
  header {
    width: 80%;
    max-width: 1500px;
  }
  .navbar {
    height: 97px;
  }
}
</style>
