import VueRouter from 'vue-router'
import auth from './auth.js'

import Login from './components/Login.vue'
import MyAccount from './components/settings/MyAccount.vue'
import MySettings from './components/settings/MySettings.vue'
import Dashboard from './components/Dashboard.vue'
import D_NonProducedOrder from './components/order/D_NonProducedOrder.vue'
import E_NonProducedOrder from './components/order/E_NonProducedOrder.vue'
import T_NonProducedOrder from './components/order/T_NonProducedOrder.vue'
import S_NonProducedOrder from './components/order/S_NonProducedOrder.vue'
import P_NonProducedOrder from './components/order/P_NonProducedOrder.vue'
import T_OpenOrder from './components/order/T_OpenOrder.vue'
import T_OpenOrderStock from './components/order/T_OpenOrderStock.vue'
import S_OpenOrder from './components/order/S_OpenOrder.vue'
import C_OpenOrder from './components/order/C_OpenOrder.vue'
import E_OpenOrder from './components/order/E_OpenOrder.vue'
import D_ProducedOrder from './components/order/D_ProducedOrder.vue'
import E_ProducedOrder from './components/order/E_ProducedOrder.vue'
import T_ProducedOrder from './components/order/T_ProducedOrder.vue'
import S_ProducedOrder from './components/order/S_ProducedOrder.vue'
import P_ProducedOrder from './components/order/P_ProducedOrder.vue'
import D_Stock from './components/stock/D_Stock.vue'
import C_Stock from './components/stock/C_Stock.vue'
import E_Stock from './components/stock/E_Stock.vue'
import T_Stock from './components/stock/T_Stock.vue'
import S_Stock from './components/stock/S_Stock.vue'
import P_Stock from './components/stock/P_Stock.vue'
import D_DaysInStock from './components/stock/D_DaysInStock.vue'
import C_DaysInStock from './components/stock/C_DaysInStock.vue'
import E_DaysInStock from './components/stock/E_DaysInStock.vue'
import T_DaysInStock from './components/stock/T_DaysInStock.vue'
import S_DaysInStock from './components/stock/S_DaysInStock.vue'
import P_DaysInStock from './components/stock/P_DaysInStock.vue'
import Dispatch from './components/dispatch/Dispatch.vue'
import OpenItem from './components/payables/OpenItem.vue'
import Invoice from './components/payables/Invoice.vue'
import CalloffBasket from './components/calloff/CalloffBasket.vue'
import CalloffArchive from './components/calloff/CalloffArchive.vue'
import LastLoading from './components/ifile/LastLoading.vue'
import Certificate from './components/certificate/Certificate.vue'
import News from './components/news/News.vue'
import NewsDetail from './components/news/NewsDetail.vue'
import CustomerDoc from './components/customerdoc/CustomerDoc.vue'
import AboConfiguration from './components/aboreport/AboConfiguration.vue'
import UserAdmin from './components/user/UserAdmin.vue'
import OldOrderHistory from './components/order/OldOrderHistory.vue'
const LoadingLog = () => import(/* webpackChunkName: "admin" */ './components/ifile/LoadingLog.vue')
const OpenItemAdmin = () => import(/* webpackChunkName: "admin" */ './components/payables/OpenItemAdmin.vue')
const NewsAdmin = () => import(/* webpackChunkName: "admin" */ './components/news/NewsAdmin.vue')
const NewsAdminDetail = () => import(/* webpackChunkName: "admin" */ './components/news/NewsAdminDetail.vue')
const CustomerDocSectionAdmin = () => import(/* webpackChunkName: "admin" */ './components/customerdoc/CustomerDocSectionAdmin.vue')
const CustomerDocAdmin = () => import(/* webpackChunkName: "admin" */ './components/customerdoc/CustomerDocAdmin.vue')
const ResourceOverview = () => import(/* webpackChunkName: "admin" */ './components/systemadmin/ResourceOverview.vue')
const SchedulerJobList = () => import(/* webpackChunkName: "admin" */ './components/systemadmin/SchedulerJobList.vue')
const UserChangeLog = () => import(/* webpackChunkName: "admin" */ './components/user/UserChangeLog.vue')

//Vue.use(VueRouter)

// TODO empty URL /#/ redirect to /#/dashboard
const router = new VueRouter({
  routes:  [
    { path: '/login', component: Login, meta: { privs: null } },
    { path: '/dashboard', component: Dashboard, meta: { privs: null } },
    { path: '/news', component: News, props: {newsType: 'NEWS'}, meta: { privs: null } },
    { path: '/news/:newsHeadId', component: NewsDetail, props: true, meta: { privs: null }},
    { path: '/expertise', component: News, props: {newsType: 'EXPERTISE'}, meta: { privs: null } },
    { path: '/expertise/:newsHeadId', component: NewsDetail, props: true, meta: { privs: null }},
    { path: '/innovations', component: News, props: {newsType: 'INNOVATIONS'}, meta: { privs: null } },
    { path: '/innovations/:newsHeadId', component: NewsDetail, props: true, meta: { privs: null }},
    { path: '/docs', component: CustomerDoc, meta: { privs: null } },
    { path: '/myaccount', component: MyAccount, meta: { privs: null } },
    { path: '/mysettings', component: MySettings, meta: { privs: null } },
    { path: '/aboconfig', component: AboConfiguration, meta: { privs: null } },
    { path: '/dnonproducedorder/:rnd?', component: D_NonProducedOrder, meta: { privs: ['D_Non_Prod_Order_CG', 'D_Non_Prod_Order_C_M'] } },
    { path: '/enonproducedorder/:rnd?', component: E_NonProducedOrder, meta: { privs: ['E_Non_Prod_Order_CG', 'E_Non_Prod_Order_C_M'] } },
    { path: '/tnonproducedorder/:rnd?', component: T_NonProducedOrder, meta: { privs: ['T_Non_Prod_Order_CG', 'T_Non_Prod_Order_C_M'] } },
    { path: '/snonproducedorder/:rnd?', component: S_NonProducedOrder, meta: { privs: ['S_Non_Prod_Order_C_M', 'S_Non_Prod_Order_OL'] } },
    { path: '/pnonproducedorder/:rnd?', component: P_NonProducedOrder, meta: { privs: ['P_Non_Prod_Order_CG', 'P_Non_Prod_Order_C_M'] } },
    { path: '/topenorderstock/:rnd?', component: T_OpenOrderStock, meta: { privs: ['T_Open_Order_Stock_OL'] } },
    { path: '/copenorder/:rnd?', component: C_OpenOrder, meta: { privs: ['C_Open_Order_CG', 'C_Open_Order_C_M'] } },
    { path: '/eopenorder/:rnd?', component: E_OpenOrder, meta: { privs: ['E_Open_Order_C_M', 'E_Open_Order_O'] } },
    { path: '/topenorder/:rnd?', component: T_OpenOrder, meta: { privs: ['T_Open_Order_CG', 'T_Open_Order_C_M'] } },
    { path: '/sopenorder/:rnd?', component: S_OpenOrder, meta: { privs: ['S_Open_Order_C_M', 'S_Open_Order_OL'] } },
    { path: '/dproducedorder/:rnd?', component: D_ProducedOrder, meta: { privs: ['D_Prod_Order_CG', 'D_Prod_Order_C_M'] } },
    { path: '/eproducedorder/:rnd?', component: E_ProducedOrder, meta: { privs: ['E_Prod_Order_CG', 'E_Prod_Order_C_M'] } },
    { path: '/tproducedorder/:rnd?', component: T_ProducedOrder, meta: { privs: ['T_Prod_Order_CG', 'T_Prod_Order_C_M'] } },
    { path: '/sproducedorder/:rnd?', component: S_ProducedOrder, meta: { privs: ['S_Prod_Order_C_M', 'S_Prod_Order_OL', ] } },
    { path: '/pproducedorder/:rnd?', component: P_ProducedOrder, meta: { privs: ['P_Prod_Order_CG', 'P_Prod_Order_C_M'] } },
    { path: '/oldorderhistory', component: OldOrderHistory, meta: { privs: ['ORDER.OLD_HISTORY'] } },
    { path: '/dstock/:rnd?', component: D_Stock, meta: { privs: ['D_CustStock_CG', 'D_CustStock_C_M'] } },
    { path: '/cstock/:rnd?', component: C_Stock, meta: { privs: ['C_CustStock_CG', 'C_CustStock_C_M'] } },
    { path: '/estock/:rnd?', component: E_Stock, meta: { privs: ['E_CustStock_CG', 'E_CustStock_C_M'] } },
    { path: '/tstock/:rnd?', component: T_Stock, meta: { privs: ['T_CustStock_CG', 'T_CustStock_C_M'] } },
    { path: '/sstock/:rnd?', component: S_Stock, meta: { privs: ['S_CustStock_C_M', 'S_CustStock_OL'] } },
    { path: '/pstock/:rnd?', component: P_Stock, meta: { privs: ['P_CustStock_CG', 'P_CustStock_C_M'] } },
    { path: '/ddaysinstock/:rnd?', component: D_DaysInStock, meta: { privs: ['D_CustStock_C_M'] } },
    { path: '/cdaysinstock/:rnd?', component: C_DaysInStock, meta: { privs: ['C_CustStock_C_M'] } },
    { path: '/edaysinstock/:rnd?', component: E_DaysInStock, meta: { privs: ['E_CustStock_C_M'] } },
    { path: '/tdaysinstock/:rnd?', component: T_DaysInStock, meta: { privs: ['T_CustStock_C_M'] } },
    { path: '/sdaysinstock/:rnd?', component: S_DaysInStock, meta: { privs: ['S_CustStock_C_M', 'S_CustStock_OL'] } },
    { path: '/pdaysinstock/:rnd?', component: P_DaysInStock, meta: { privs: ['P_CustStock_C_M'] } },
    { path: '/dispatch/:rnd?', component: Dispatch, meta: { privs: ['Dispatch_CG', 'Dispatch_C_M', 'Dispatch_DP'] } },
    { path: '/openitem/:rnd?', component: OpenItem, meta: { privs: ['Open_Item_CG', 'Open_Item_C_M', 'Open_Item_D'] } },
    { path: '/invoice/:rnd?', component: Invoice, meta: { privs: ['Invoice_H'] } },
    { path: '/calloff/basket', component: CalloffBasket, meta: { privs: ['CALLOFF.BASKET'] } },
    { path: '/calloff/archive', component: CalloffArchive, meta: { privs: ['CALLOFF.ARCHIVE'] } },
    { path: '/lastloading', component: LastLoading, meta: { privs: ['Mill_File_Loading'] } },
    { path: '/certificate', component: Certificate, meta: { privs: ['CERTIFICATE'] } },
    { path: '/certificate/:millId/:certificateNo', component: Certificate, meta: { privs: ['CERTIFICATE'] } },
    { path: '/certificate/order/:millId/:orderNo/:orderPosNo?', component: Certificate, meta: { privs: ['CERTIFICATE'] } },
    { path: '/admin/user', component: UserAdmin, meta: { privs: ['USERADMIN'] } },
    { path: '/admin/userlog', component: UserChangeLog, meta: { privs: ['AUDITING.VIEWER'] } },
    { path: '/admin/loadinglog', component: LoadingLog, meta: { privs: ['KIS.LOADINGLOG'] } },
    { path: '/admin/openitem', component: OpenItemAdmin, meta: { privs: ['OPEN_ITEM.ADMIN'] } },
    { path: '/admin/news', component: NewsAdmin, meta: { privs: ['NEWS.EDIT'] } },
    { path: '/admin/news/:newsHeadId', component: NewsAdminDetail, props: true, meta: { privs: ['NEWS.EDIT'] }},
    { path: '/admin/cusdocsec', component: CustomerDocSectionAdmin, meta: { privs: ['CUSTOMER_DOC.ADMIN'] } },
    { path: '/admin/cusdoc', component: CustomerDocAdmin, meta: { privs: ['CUSTOMER_DOC.ADMIN'] } },
    { path: '/admin/resoverview', component: ResourceOverview, props: true , meta: { privs: ['ADMIN.RESOVERVIEW'] }},
    { path: '/admin/schedjoblist', component: SchedulerJobList, props: true , meta: { privs: ['ADMIN.SCHED_JOB_LIST'] }},
  ]
})

// add auth to the routes
router.beforeEach((to, from, next) => {
    //console.log('beforeEach: routing to ' + to.path + (typeof to.query.redirect !== 'undefined' ? ' redirect to ' + to.query.redirect : '') + ' (from ' + from.path + ')')
    if (to.meta.privs != null && to.meta.privs != []) {
        let user = router.app.user
        //if (router.app.user)
        //    user = router.app.user

        let userPromise = null
        if (user == null)
            userPromise = auth.getLoggedInUser()
        else
            userPromise = Promise.resolve(user)

        userPromise.then(user => {
            //console.log('checking priv ' + to.meta.privs + ' for path ' + to.path + ' of user', user)
            if (user != null && auth.isUserAuthorizedForAnyPriv(user, to.meta.privs)) {
                //console.log(' YES! user = ' + user)
                next()
            }
            else {
                //console.log(' NO! -> next to login with redirect to ', to.fullPath)
                next({
                    path: '/login',
                    query: { redirect: to.fullPath }
                })
            }
        })
    }
    else {
        //console.log('no privs required -> next')
        next()
    }
})

export default router;
