import { render, staticRenderFns } from "./NewsDetail.vue?vue&type=template&id=647eeca1&scoped=true&"
import script from "./NewsDetail.vue?vue&type=script&lang=js&"
export * from "./NewsDetail.vue?vue&type=script&lang=js&"
import style0 from "./NewsDetail.vue?vue&type=style&index=0&id=647eeca1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647eeca1",
  null
  
)

export default component.exports