<template>
<div id="news" class="cip-content">

    <div class="card">
        <div class="card-body" v-if="newsHead != null">
            <h5 class="card-title">
                <div class="container-fluid m-0 p-0">
                    <div class="row">
                        <div class="col-9 header-line-text">
                            <span v-if="newsHead.text4User != null">{{ newsHead.text4User.headerText }}</span>
                        </div>
                        <div class="col-1">
                            <a class="btn btn-light" :href="getAttachmentURL(newsHead.id, newsHead.attachedFileLangCode4User)" v-if="newsHead.attachedFileLangCode4User != null">
                                <font-awesome-icon icon="file-alt" />
                            </a>
                        </div>
                        <div class="col-2 header-line-text">
                            {{ newsHead.publishingDate | cipFormatDate }}
                        </div>
                    </div>
                </div>
            </h5>

            <div class="card-text ql-editor" style="font-size: 16px">
                <div v-html="newsHead.text4User.bodyText" v-if="newsHead.text4User != null"></div>
            </div>

            <spinner :status="newsIsLoading" :size="35" :speed="1">
                <!--:color="spinnerColor" -->
            </spinner>

            <button type="reset" class="btn-close" @click="closeNewsDetailView()">
                <font-awesome-icon icon="times"/>
            </button>
        </div>

        <div class="card-body" v-if="newsHead == null">
            {{ $t('news.notfound')}}
        </div>

    </div>

</div>
</template>

<script>
import Spinner from 'vue-spinner-component/src/Spinner.vue'
import cipAxios, { handleHttpError } from '@/cipAxios'
import cipGraphQLClient from '@/cipGraphQLClient'
import * as cipDateLib from '@/cipDateLib'

export default {
    name: 'newsHead',
    props: [ 'newsHeadId' ],
    inject: ['eventBus'],
    components: {
        Spinner
    },
    data () {
        return {
            newsIsLoading: false,
            newsHead: {},
        }
    },

    created: function() {
        this.setNewsHead()
    },

    methods: {
        setNewsHead() {
            const query =
                `query newsHead($newsHeadId: Long!, $atDateTime: LocalDateTime!) {
                    newsHead(newsHeadId: $newsHeadId, atDateTime: $atDateTime) {
                        id
                        publishingDate
                        text4User {
                            langCode
                            headerText
                            bodyText
                        }
                        attachedFileLangCode4User
                        readByUser
                    }
                }`
            this.newsIsLoading = true
            cipGraphQLClient.request(query, {
                newsHeadId: this.newsHeadId,
                atDateTime: cipDateLib.createLocalDateTime(new Date())
            })
            .then(data => {
                this.newsIsLoading = false
                this.newsHead = data.newsHead
            })
            .catch(error => {
                handleHttpError(error.response.status, error.response.error)
            })
        },

        setNewsHeadREST() {
            this.newsIsLoading = true
            cipAxios.get('rest/news/' + this.newsHeadId)
            .then(response => {
                this.newsIsLoading = false
                this.newsHead = response.data
            })
        },

        getAttachmentURL(newsHeadId, langCode) {
            let prefix = (process.env.VUE_APP_BACKEND_URL == '' ? '' : process.env.VUE_APP_BACKEND_URL + '/')
            return prefix + 'rest/news/' + newsHeadId + '/' + langCode + '/attachment'
        },

        closeNewsDetailView() {
            this.markNewsRead(this.newsHeadId)
            .then(() => {
                // this.$router.push('/news')
                this.$router.go(-1)
            })
        },

        markNewsRead(newsHeadId) {
            return cipAxios.put('rest/news/'  + newsHeadId + '/markread')
            .then(() => {
                this.eventBus.$emit('news-read')
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.header-line-text {
    padding-top: 8px;
    padding-bottom: 8px;
}
</style>
